import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';
import DialogSendDownloadUrl from '../../components/dialogs/SendDownloadUrl';

const section = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const container = css`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1224px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
`;

const TopBannerSection = styled.section`
  ${section}
  position: relative;
  background-color: #4052ff;
`;

const TopBannerContainer = styled.div`
  ${container}
  text-align: center;
  padding: 20px 0;
  color: #fff;
  line-height: 20px;
`;

const TopBannerText = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
  text-align: center;
`;

const TopBannerIcon = styled.img`
  display: inline-block;
  line-height: 20px;
  margin-left: 5px;
  cursor: pointer;
`;

const CloseTopBanner = styled.button`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #4052ff;
  border: none;
  cursor: pointer;
`;

const CloseIcon = styled.img`
  display: inline-block;
`;

export default function TopBanner(): ReactElement {
  const [close, setClose] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  return (
    <TopBannerSection style={{ display: close ? 'none' : 'flex' }}>
      <TopBannerContainer>
        <TopBannerText style={{ fontWeight: 'bold', marginRight: '5px' }}>
          모두의 학회, 아리스! 앱 다운받고
        </TopBannerText>
        <TopBannerText>언제 어디서나 학술대회에 참여하세요.</TopBannerText>
        <TopBannerIcon
          src="/static/home/aris-download.png"
          alt="download icon"
          onClick={() => setShowDialog(true)}
        />
      </TopBannerContainer>
      <CloseTopBanner
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setClose(true);
        }}
      >
        <CloseIcon src="/static/home/aris-close.png" alt="download icon" />
      </CloseTopBanner>
      <DialogSendDownloadUrl
        isOpen={showDialog}
        handleClose={() => setShowDialog(false)}
      />
    </TopBannerSection>
  );
}
