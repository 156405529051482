import { ReactNode, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { getUserMe } from '../apis';
import { userState } from '../store/atom';

type AuthCheckerProps = {
  children: ReactNode;
};

export default function AuthChecker({ children }: AuthCheckerProps) {
  const [user, setUser] = useRecoilState(userState);

  useEffect(() => {
    if (!user) {
      (async () => {
        try {
          const result = await getUserMe();
          if (result?.user) {
            setUser(result.user);
          }
        } catch (e) {
          console.error(`getUserMe: ${JSON.stringify(e)}`);
        }
      })();
    }
  }, [user]);

  return <>{children}</>;
}
