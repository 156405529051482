import React, { ReactElement, ReactNode, useState } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/atom';
import HomeFooter from './HomeFooter';
import HomeHeader from './HomeHeader';
import TopBanner from './TopBanner';

interface Props {
  children: ReactNode;
}

export default function Index({ children }: Props): ReactElement {
  const [user] = useRecoilState(userState);
  return (
    <>
      <TopBanner />
      <HomeHeader user={user} />
      {children}
      <HomeFooter />
    </>
  );
}
