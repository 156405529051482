import { User } from '../schemas/types';
import { fetchGet, fetchPost, fetchPut, ROOT_URL } from './common';

export const sendMessage = async (receiver: string) => {
  try {
    const result = await fetchPost({ receiver }, '/api/sendDownloadUrl');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(`sendMessage: ${error}`);
    return error;
  }
};

export const getPrograms = async (confId: number) => {
  try {
    const result = await fetch(`${ROOT_URL}/api/web/conf/${confId}/program`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getKosesPoster = async () => {
  try {
    const result = await fetch(`${ROOT_URL}/api/web/koses/poster`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reportToken = async (firebaseId: string, token: string) => {
  try {
    const result = await fetchPost({ firebaseId, token }, '/api/fbToken');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const signup = async (
  email: string,
  password: string,
  name?: string,
  phone?: string
) => {
  const obj = { email, password, name, phone };

  try {
    const result = await fetchPost(obj, '/api/signup');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const login = async (email: string, password: string) => {
  const obj = { email, password };

  try {
    const result = await fetchPost(obj, '/api/login');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const sendVerifiedEmail = async (email: string) => {
  try {
    const result = await fetchPost({ email }, '/api/verify-email');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const socialLogin = async (
  email: string,
  uid: string,
  providerData: object
) => {
  const obj = { email, uid, providerData };

  try {
    const result = await fetchPost(obj, '/api/webSignIn');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const logout = async () => {
  try {
    const result = await fetchGet('/api/logout');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const sendPasswordResetEmail = async (email: string) => {
  const obj = { email };

  try {
    const result = await fetchPost(obj, '/api/findPassword');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updatePassword = async (password: string, newPassword: string) => {
  const obj = { password, newPassword };

  try {
    const result = await fetchPost(obj, '/api/updatePassword');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getUserMe = async () => {
  try {
    const result = await fetchGet('/api/user/me');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getRegisteredConf = async (confId: number) => {
  try {
    const result = await fetchGet(`/api/user/me/auth/${confId}`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

type UserProperties = Omit<User, 'id'>;

export const updateUserMe = async (props: UserProperties) => {
  const user = { ...props };

  try {
    const result = await fetchPut({ user }, '/api/user/me');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getPreSignedUrl = async (keyname: string, filename: any) => {
  try {
    const result = await fetchGet(
      `/api/presignedUrl?key=${keyname}&filename=${filename}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const uploadToBucket = async (preSignedUrl: string, file: File) => {
  try {
    const option = {
      method: 'PUT',
      body: file
    };

    let result: any = await fetch(preSignedUrl, option);

    const s3Url = preSignedUrl.split('?')[0];
    if (!result) result = {};
    if (!result.data)
      result.data = {
        url: s3Url
      };

    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCommentList = async (presentationId: number) => {
  try {
    const result = await fetchGet(
      `/api/web/presentation/${presentationId}/comment`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const postComment = async (commentObj: any) => {
  try {
    const result = await fetchPost(
      commentObj,
      `/api/web/presentation/${commentObj.presentationId}/comment`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getConferences = async function (params: string) {
  try {
    const result = await fetchGet(`/api/web/conf${params}`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getConference = async function (confId: number | string) {
  try {
    const result = await fetchGet(`/api/web/conf/${confId}`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSessionsGroupByLocation = async (
  confId: number,
  date: string
) => {
  try {
    const result = await fetchGet(
      `/api/web/conf/${confId}/session-schedule/${date}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    return error;
  }
};

export const getConferenceDateList = async function (confId: number) {
  try {
    const result = await fetchGet(`/api/web/conf/${confId}/date`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSessionDetails = async function (
  confId: number,
  sessionId: string
) {
  try {
    const result = await fetchGet(
      `/api/web/conf/${confId}/session/${sessionId}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const addLog = async (logObj: Object) => {
  try {
    const result = await fetchPost({ ...logObj, platform: 'web' }, '/api/log');
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const confirmInviteCode = async (
  confId: number | undefined,
  inviteCode: string
) => {
  try {
    const result = await fetchPut(
      { inviteCode },
      `/api/conf/${confId}/inviteCode`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const postShowCaseLog = function (
  url: string | null,
  src: string | null
) {
  return fetch(`${ROOT_URL}/api/conference/log/showCase`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ url, src }),
    credentials: 'include'
  }).then((res) => res.json());
};

export const sendQuestion = async (request: any) => {
  try {
    const result = await fetchPost(
      request,
      `/api/conf/${request.conferenceId}/question`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getBookmark = async function () {
  try {
    const result = await fetchGet(`/api/conferenceLike`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const toggleBookmarkConference = async function (
  data: any,
  confId: number
) {
  try {
    const result = await fetchPut({ data }, `/api/conf/${confId}/bookmark`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const searchConferences = async function (keyword: string) {
  try {
    const result = await fetchGet(
      `/api/web/conf/search?keyword=${keyword || ''}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSessionList = async (confId: number) => {
  try {
    const result = await fetch(`${ROOT_URL}/api/web/conf/${confId}/session`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSponsorList = async (sid: number) => {
  try {
    const result = await fetch(`${ROOT_URL}/api/web/session/${sid}/sponsor`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getChatList = async (sid: number) => {
  try {
    const result = await fetch(`${ROOT_URL}/api/web/session/${sid}/chat`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const insertChat = async (chat: any) => {
  try {
    const result = await fetchPost(
      { chat },
      `/api/web/session/${chat?.sessionId}/chat`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSponsorsVisit = async (confId: number) => {
  try {
    const result = await fetchGet(`/api/conference/${confId}/sponsor/visit`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateSponsorVisit = async (confId: number, sponsorId: number) => {
  try {
    const result = await fetchPut(
      {},
      `/api/conference/${confId}/sponsor/${sponsorId}/visit`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSessionListAtPlace = async (sessionId: number) => {
  try {
    const result = await fetch(`${ROOT_URL}/api/web/session/${sessionId}/mate`);
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getVideoAd = async (confId: number, type: string) => {
  try {
    const result = await fetchGet(
      `/api/app/conf/${confId}/videoAd?type=${type}`
    );
    const resultJson = await result.json();
    return resultJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};
