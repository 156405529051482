import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { logout } from '../../apis';
import { authService } from '../../firebase';
import { User } from '../../schemas/types';
import { userState } from '../../store/atom';
import { searchConferences } from '../../apis';
import moment from 'moment';

const Divider = styled.div`
  height: 0.5px;
  background-color: #646464;
  width: 40%;
  margin: 11px 0;
  margin-left: 24px;
`;

const VerticalDivider = styled.div`
  height: 12px;
  width: 1px;
  margin: 0 32px;
  background-color: #d7d7d7;
  height: 32px;
`;

const section = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const container = css`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1224px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
`;

const HeaderSection = styled.section`
  ${section}
`;

const HeaderContainer = styled.div`
  ${container}
  padding: 26px 12px;
`;

const Logo = styled.img`
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
`;

const SearchBox = styled.div`
  position: relative;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const SearchInputBox = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  font-size: 14px;
  padding: 15px 20px;
  border: none;
  border-radius: 24px;
  background-color: #f4f4f4;
  width: 380px;
  outline: none;
  letter-spacing: -0.4px;
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

const SearchList = styled.ul`
  position: absolute;
  top: 120%;
  background-color: #353535;
  list-style: none;
  color: #a7a7a7;
  padding: 15px 0;
  border-radius: 6px;
  box-shadow: 0 2px 5px #353535;
  z-index: 100;
`;

const SearchItem = styled.li`
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:hover {
    background-color: #4d4d4d;
  }
`;

const SearchConference = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-right: 60px;
`;

const SearchConferenceName = styled.span`
  font-size: 15px;
  color: #fbfbfb;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: -0.6px;
`;

const SearchConferencePeriod = styled.span`
  font-size: 13px;
  letter-spacing: -0.2px;
`;

const SearchInstitute = styled.span`
  font-size: 11px;
  line-height: 22px;
  white-space: nowrap;
  text-align: left;
  width: 110px;
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: -0.4px;
`;

const ViewMoreItem = styled.li`
  text-align: center;
  width: 100%;
  &:hover {
    background-color: #4d4d4d;
  }
  cursor: pointer;
`;

const MoveIcon = styled.img`
  flex: none;
  align-self: flex-start;
  transform: translateX(12px);
`;

const CategoryBox = styled.div`
  position: relative;
`;

const CategoryToggle = styled.button`
  height: 48px;
  cursor: pointer;
  border: none;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryToggleText = styled.div`
  padding: 15px 20px;
  padding-right: 8px;
  font-size: 17px;
  color: #353535;
`;

const ArrowIcon = styled.img``;

const CategoryList = styled.div`
  position: absolute;
  top: 120%;
  background-color: #353535;
  list-style: none;
  color: #a7a7a7;
  border-radius: 6px;
  box-shadow: 0 2px 5px #353535;
  min-width: 192px;
  padding: 4px 0;
  z-index: 100;
`;

const CategoryItem = styled.div`
  width: 100%;
  padding: 16px 24px;
  cursor: pointer;
  &:hover {
    background-color: #4d4d4d;
  }
`;

const CategoryName = styled.div`
  white-space: nowrap;
  color: #fbfbfb;
  letter-spacing: -0.4px;
`;

const CategoryEngName = styled.div`
  font-size: 13px;
  white-space: nowrap;
  letter-spacing: -0.2px;
`;

const GoAdminPage = styled.a`
  color: #353535;
  text-decoration: none;
  padding: 16px 0 16px 24px;
`;

const NotiBox = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 37px;
  display: flex;
`;

const NotiButton = styled.button`
  border: none;
  background-color: #fff;
  cursor: pointer;
`;

const NotiIcon = styled.img``;

const NotiView = styled.div`
  position: absolute;
  top: calc(120% + 17px);
  right: 0;
  background-color: #353535;
  list-style: none;
  color: #fbfbfb;
  border-radius: 6px;
  box-shadow: 0 2px 5px #353535;
  min-width: 192px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

const NotiEmpty = styled.div`
  white-space: nowrap;
  padding: 10px 24px;
  padding-right: 48px;
  font-size: 14px;
  letter-spacing: -0.6px;
`;

const NotiList = styled.ul`
  list-style: none;
`;

const NotiItem = styled.li`
  position: relative;
  padding: 10px 24px;
  &:hover {
    background-color: #4d4d4d;
  }
`;

const NotiTitle = styled.div`
  padding-right: 40px;
  font-size: 14px;
  letter-spacing: -0.4px;
`;

const NotiCreateAt = styled.div`
  color: #a7a7a7;
  margin-top: 4px;
  font-size: 12px;
  letter-spacing: -0.4px;
`;

const CloseNoti = styled.button`
  display: inline-block;
  position: absolute;
  background: none;
  border: none;
  top: 10px;
  right: 24px;
  cursor: pointer;
`;

const NotiToolbar = styled.div`
  padding: 10px 24px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  font-size: 11px;
`;

const ReadAllNoti = styled.button`
  border: none;
  background-color: #353535;
  cursor: pointer;
  color: #646464;
  margin-right: auto;
  letter-spacing: -0.2px;
`;

const CloseNotiView = styled.button`
  border: none;
  background-color: #353535;
  cursor: pointer;
  color: #646464;
  letter-spacing: -0.2px;
`;

const GoToLogin = styled(Link)`
  text-decoration: none;
  color: #646464;
`;

const ProfileBox = styled.div`
  position: relative;
`;

const ProfileButtion = styled.button`
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileImage = styled.img`
  background-color: #353535;
  display: inline-block;
  vertical-align: top;
`;

const ProfileMenuList = styled.ul`
  position: absolute;
  top: calc(120% + 17px);
  right: 0;
  background-color: #353535;
  list-style: none;
  color: #a7a7a7;
  border-radius: 6px;
  box-shadow: 0 2px 5px #353535;
  min-width: 192px;
  padding: 10px 0;
  z-index: 100;
`;

const ProfileMenuItem = styled.li<{ type: string }>`
  width: 100%;
  padding: 10px 24px;
  cursor: pointer;
  letter-spacing: -0.4px;
  &:hover {
    background-color: #4d4d4d;
  }
  ${({ type }) => {
    if (type === 'main') {
      return css`
        color: #fbfbfb;
      `;
    } else {
      return css`
        color: #d7d7d7;
        font-size: 14px;
      `;
    }
  }}
`;

interface Props {
  user: User | null;
}

export default function HomeHeader({ user }: Props): ReactElement {
  const history = useHistory();
  const [, setUser] = useRecoilState(userState);
  const [showDropdown, setShowDropdown] = useState<
    'search' | 'category' | 'notice' | 'usermenu'
  >();

  const [searchText, setSearchText] = useState<string>('');
  const [confList, setConfList] = useState<any>([]);
  const [hasMore, setHasMore] = useState(false);

  const onChangeSearchText = async (e: any) => {
    const keyword = e.target.value;
    setSearchText(keyword);
    if (keyword.length < 3)
      // if too short keyword, skip.
      return;
    const result = await searchConferences(keyword);
    const { searchConf, hasMore } = result;

    if (!searchConf?.length) return;

    setConfList(searchConf);
    setHasMore(hasMore);
    setShowDropdown('search');
  };

  const onKeyPressSearchText = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = (selectedArea?: string) => {
    history.push(
      `/aris/search?searchText=${searchText}&${
        selectedArea ? `selectedArea=${selectedArea}` : ''
      }`
    );
    window.location.reload();
    setSearchText('');
  };

  const handleLogout = () => {
    try {
      authService
        .signOut()
        .then(async () => {
          const result = await logout();
          setUser(null);
          history.push('/');
        })
        .catch((error: any) => {
          console.error(`logout error: ${error}`);
        });
    } catch (e) {
      console.error(`handleLogout error: ${e}`);
    }
  };

  function onClickBackground() {
    setShowDropdown(undefined);
  }

  function toggleDropdown(
    e: any,
    value: 'search' | 'category' | 'notice' | 'usermenu' | undefined
  ) {
    e.stopPropagation();
    e.preventDefault();
    if (value === showDropdown) {
      setShowDropdown(undefined);
    } else {
      setShowDropdown(value);
    }
  }

  useEffect(() => {
    window.addEventListener('click', onClickBackground);
    return () => {
      window.removeEventListener('click', onClickBackground);
    };
  }, []);

  return (
    <HeaderSection>
      <HeaderContainer>
        <Logo
          src="/static/home/aris-logo.svg"
          alt="로고 이미지"
          onClick={() => history.push('/aris')}
        />
        <SearchBox>
          <SearchInputBox>
            <SearchInput
              type="text"
              placeholder="원하는 학회나 학술대회를 검색해보세요"
              onClick={(e) => toggleDropdown(e, 'search')}
              onChange={(e) => onChangeSearchText(e)}
              onKeyPress={(e) => onKeyPressSearchText(e)}
              value={searchText}
            />
            <SearchIcon
              src="/static/home/aris-search.png"
              alt="검색 아이콘"
              onClick={() => handleSearch()}
            />
          </SearchInputBox>
          {showDropdown === 'search' && (
            <SearchList>
              {confList.map((conf: any) => (
                <SearchItem
                  key={conf.id}
                  onMouseDown={() => {
                    history.push(`/conference/${conf.id}`);
                  }}
                >
                  <SearchConference>
                    <SearchConferenceName>
                      {conf.title}
                      {/* 2021 춘계 학술대회 및 정기총회 무선수송워크샵 */}
                    </SearchConferenceName>
                    <SearchConferencePeriod>
                      {moment(conf?.startDate).format('YY.MM.DD') +
                        '  ~  ' +
                        moment(conf?.endDate).format('YY.MM.DD')}
                    </SearchConferencePeriod>
                  </SearchConference>
                  <SearchInstitute>{conf.instituteTitle || ''}</SearchInstitute>
                  <MoveIcon
                    src="/static/home/aris-arrow-forward.png"
                    alt="학회 이동 링크 아이콘"
                  />
                </SearchItem>
              ))}
              {(confList.length && hasMore && (
                <ViewMoreItem
                  onMouseDown={() => {
                    handleSearch();
                  }}
                >
                  더보기
                </ViewMoreItem>
              )) ||
                null}
            </SearchList>
          )}
        </SearchBox>
        <CategoryBox>
          <CategoryToggle onClick={(e) => toggleDropdown(e, 'category')}>
            <CategoryToggleText>분야별 학술대회</CategoryToggleText>
            <ArrowIcon
              src="/static/home/aris-dropdown-arrow.png"
              alt="화살표 아이콘"
            />
          </CategoryToggle>
          {showDropdown === 'category' && (
            <CategoryList>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('1');
                }}
              >
                <CategoryName>인문학</CategoryName>
                <CategoryEngName>humanities</CategoryEngName>
              </CategoryItem>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('2');
                }}
              >
                <CategoryName>사회과학</CategoryName>
                <CategoryEngName>Sozialwissenschaft</CategoryEngName>
              </CategoryItem>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('3');
                }}
              >
                <CategoryName>자연과학</CategoryName>
                <CategoryEngName>engineering</CategoryEngName>
              </CategoryItem>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('4');
                }}
              >
                <CategoryName>공학</CategoryName>
                <CategoryEngName>natural science</CategoryEngName>
              </CategoryItem>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('5');
                }}
              >
                <CategoryName>의약학</CategoryName>
                <CategoryEngName>Medicine and Pharmacy</CategoryEngName>
              </CategoryItem>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('6');
                }}
              >
                <CategoryName>농수해양학</CategoryName>
                <CategoryEngName>
                  <div>Agricultural and</div>
                  <div>Water Marine Engineering</div>
                </CategoryEngName>
              </CategoryItem>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('7');
                }}
              >
                <CategoryName>예술체육학</CategoryName>
                <CategoryEngName>Arts and Physical Education</CategoryEngName>
              </CategoryItem>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('8');
                }}
              >
                <CategoryName>복합학</CategoryName>
                <CategoryEngName>Interdisciplinary Science</CategoryEngName>
              </CategoryItem>
              <CategoryItem
                onMouseDown={() => {
                  handleSearch('9');
                }}
              >
                <CategoryName>기타</CategoryName>
                <CategoryEngName>Etc.</CategoryEngName>
              </CategoryItem>
            </CategoryList>
          )}
        </CategoryBox>
        <GoAdminPage href="http://biz.aris.club" target="_blank">
          학술대회 개설
        </GoAdminPage>
        <VerticalDivider></VerticalDivider>
        <NotiBox>
          <NotiButton onClick={(e) => toggleDropdown(e, 'notice')}>
            <NotiIcon src="/static/home/aris-bell.png" alt="알림 아이콘" />
          </NotiButton>
          {showDropdown === 'notice' && (
            <NotiView>
              <NotiEmpty>
                [아리스] 새로운 알림이 없습니다. (이전 알림 확인하기)
              </NotiEmpty>
              <Divider
                style={{
                  width: 'calc(100% - 48px)',
                  margin: '10px 24px'
                }}
              ></Divider>
              <NotiList>
                {[1, 2, 3].map((v, i) => (
                  <NotiItem key={i}>
                    <NotiTitle>
                      [한국전자파학회]의 중요일정 : 2021 하계 학술대회의 논문
                      모집 마감일이 7일 남았습니다.
                    </NotiTitle>
                    <NotiCreateAt>2021.05.30</NotiCreateAt>
                    <CloseNoti>
                      <img
                        src="/static/home/aris-close-small.png"
                        alt="노티 닫기 아이콘"
                      />
                    </CloseNoti>
                  </NotiItem>
                ))}
              </NotiList>
              <NotiToolbar>
                <ReadAllNoti>모두 읽음 처리</ReadAllNoti>
                <CloseNotiView onClick={() => setShowDropdown(undefined)}>
                  닫기
                </CloseNotiView>
              </NotiToolbar>
            </NotiView>
          )}
        </NotiBox>
        {!user && <GoToLogin to="/login">로그인/회원가입</GoToLogin>}
        {user && (
          <ProfileBox>
            <ProfileButtion onClick={(e) => toggleDropdown(e, 'usermenu')}>
              <ProfileImage src={user?.thumbnail} />
            </ProfileButtion>
            {showDropdown === 'usermenu' && (
              <ProfileMenuList>
                <ProfileMenuItem type="main">학술대회</ProfileMenuItem>
                <ProfileMenuItem
                  type="main"
                  onMouseDown={() => {
                    window.location.href = '/aris/profile';
                  }}
                >
                  회원정보 수정
                </ProfileMenuItem>
                <ProfileMenuItem type="main">알림</ProfileMenuItem>
                <Divider></Divider>
                <ProfileMenuItem
                  type="sub"
                  onMouseDown={() => {
                    window.open('https://aris.club/term.txt');
                  }}
                >
                  이용약관
                </ProfileMenuItem>
                <ProfileMenuItem
                  type="sub"
                  onMouseDown={() => {
                    window.open('https://aris.club/privacy.txt');
                  }}
                >
                  개인정보 처리방침
                </ProfileMenuItem>
                <ProfileMenuItem type="sub" onClick={handleLogout}>
                  로그아웃
                </ProfileMenuItem>
              </ProfileMenuList>
            )}
          </ProfileBox>
        )}
      </HeaderContainer>
    </HeaderSection>
  );
}
