import { atom } from 'recoil';
import { User } from '../schemas/types';

type ThemeMode = 'light' | 'dark';
type ThemeDirection = 'rtl' | 'ltr';

const ThemeModeState = atom<ThemeMode>({
  key: 'ThemeMode',
  default: 'light'
});

const ThemeDirectionState = atom<ThemeDirection>({
  key: 'ThemeDirection',
  default: 'ltr'
});

const userState = atom<User | null>({
  key: 'userState',
  default: null
});

const kscpState = atom<string>({
  key: 'kscpState',
  default: ''
});

const hasRegisteredState = atom<boolean | false>({
  key: 'hasRegisteredState',
  default: false
});

const cancelClickState = atom<boolean | false>({
  key: 'cancelClickState',
  default: false
});

export {
  ThemeModeState,
  ThemeDirectionState,
  userState,
  kscpState,
  hasRegisteredState,
  cancelClickState
};
