import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import AuthChecker from '../guards/AuthChecker';
import ArisLayout from '../layouts/aris';

// ----------------------------------------------------------------------

const ArisRoutes = {
  path: '/aris',
  checker: AuthChecker,
  layout: ArisLayout,
  routes: [
    {
      exact: true,
      path: '/aris',
      component: lazy(() => import('../views/ArisHome'))
    },
    {
      exact: true,
      path: '/aris/search',
      component: lazy(() => import('../views/ArisSearch'))
    },
    {
      exact: true,
      path: '/aris/bookmark',
      component: lazy(() => import('../views/ArisSearchBookmark'))
    },
    {
      exact: true,
      path: '/aris/ongoing',
      component: lazy(() => import('../views/ArisSearchOngoing'))
    },
    {
      exact: true,
      path: '/aris/upcoming',
      component: lazy(() => import('../views/ArisSearchUpcoming'))
    },
    {
      exact: true,
      path: '/aris/past',
      component: lazy(() => import('../views/ArisSearchPast'))
    },
    {
      exact: true,
      path: '/aris/profile',
      component: lazy(() => import('../views/ArisProfile'))
    },
    {
      component: () => <Redirect to="/aris" />
    }
  ]
};

export default ArisRoutes;
