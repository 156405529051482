import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import AuthChecker from '../guards/AuthChecker';
import HomeLayout from '../layouts/home';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  checker: AuthChecker,
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => import('../views/ArisLanding'))
    },
    {
      exact: true,
      path: '/redirect',
      component: lazy(() => import('../views/RedirectUrl'))
    },
    {
      exact: true,
      path: '/login',
      component: lazy(() => import('../views/Login'))
    },
    {
      exact: true,
      path: '/signup',
      component: lazy(() => import('../views/Signup'))
    },
    {
      exact: true,
      path: '/main',
      component: () => <Redirect to="/" />
    },
    {
      exact: true,
      path: '/kscp',
      component: lazy(() => import('../views/KSCPMain'))
    },
    {
      exact: true,
      path: '/kscp/home',
      component: lazy(() => import('../views/KSCPMain'))
    },
    {
      exact: true,
      path: '/kscp/program',
      component: lazy(() => import('../views/KSCPProgram'))
    },

    {
      exact: true,
      path: '/demo',
      component: lazy(() => import('../views/Demo/KSCPMain'))
    },
    {
      exact: true,
      path: '/demo/home',
      component: lazy(() => import('../views/Demo/KSCPMain'))
    },
    {
      exact: true,
      path: '/demo/program',
      component: lazy(() => import('../views/Demo/KSCPProgram'))
    },

    {
      exact: true,
      path: '/keris',
      component: lazy(() => import('../views/KERIS/KSCPMain'))
    },
    {
      exact: true,
      path: '/keris/home',
      component: lazy(() => import('../views/KERIS/KSCPMain'))
    },
    {
      exact: true,
      path: '/keris/program',
      component: lazy(() => import('../views/KERIS/KSCPProgram'))
    },

    {
      exact: true,
      path: '/ices2021',
      component: lazy(() => import('../views/ICES2021/ICES2021Main'))
    },
    {
      exact: true,
      path: '/ices2021/home',
      component: lazy(() => import('../views/ICES2021/ICES2021Main'))
    },
    {
      exact: true,
      path: '/ices2021/program',
      component: lazy(() => import('../views/ICES2021/ICES2021Program'))
    },

    {
      exact: true,
      path: '/conferenceSearch/:type',
      component: lazy(() => import('../views/Search'))
    },
    {
      exact: true,
      path: '/conference/:id',
      component: lazy(() => import('../views/ConferenceHome'))
    },
    {
      exact: true,
      path: '/conference/:id/home',
      component: lazy(() => import('../views/ConferenceHome'))
    },
    {
      exact: true,
      path: '/conference/:id/program',
      component: lazy(() => import('../views/ConferenceProgram'))
    },
    {
      exact: true,
      path: '/conference/:id/news',
      component: lazy(() => import('../views/ConferenceNews'))
    },
    {
      exact: true,
      path: '/conference/:id/popup',
      component: lazy(() => import('../views/ConferencePopup'))
    },
    {
      exact: true,
      path: '/conference/:id/onlineBooth',
      component: lazy(() => import('../views/ConferenceOnlineBooth'))
    },
    {
      exact: true,
      path: '/sessionPlay/:sessionId',
      component: lazy(() => import('../views/ConferencePlay'))
    }
    // ----------------------------------------------------------------------
    // {
    //   component: () => <Redirect to="/" />
    // }
  ]
};

export default HomeRoutes;
