import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

const section = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const container = css`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1224px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
`;

const FooterSection = styled.section`
  ${section}
  background-color: #353535;
`;

const FooterContainer = styled.div`
  ${container}
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  font-size: 13px;
  height: 120px;
`;

const CompanyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  box-sizing: border-box;
  align-items: flex-start;
`;

const CompanyDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CompanyDetailsInfoBold = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;
  text-align: left;
  margin-top: 2px;
  color: #a7a7a7;
`;

const CompanyDetailsInfo = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.4px;
  text-align: left;
  color: #a7a7a7;
`;

const CompanyCopyright = styled.div`
  color: #a7a7a7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.4px;
  text-align: left;
  margin-top: 4px;
`;

const VerticalDivider = styled.div<{ area?: string }>`
  height: 12px;
  width: 1px;
  margin: 0 15px;
  margin-top: 3px;
  background-color: #646464;
  ${({ area }) => {
    if (area === 'footer') {
      return css`
        background-color: #a7a7a7;
      `;
    }
  }}
`;

export default function HomeFooter(): ReactElement {
  return (
    <FooterSection>
      <FooterContainer>
        <Footer>
          <CompanyArea>
            <CompanyDetails>
              <CompanyDetailsInfoBold>
                (주)네트워크디파인즈
              </CompanyDetailsInfoBold>
              <VerticalDivider></VerticalDivider>
              <CompanyDetailsInfo>
                주소 : 경기도 성남시 분당구 성남대로 331번길 8, 20층
              </CompanyDetailsInfo>
              <VerticalDivider></VerticalDivider>
              <CompanyDetailsInfo>전화 : 031-711-0988 </CompanyDetailsInfo>
              <VerticalDivider></VerticalDivider>
              <CompanyDetailsInfo>
                이메일(제휴문의) : aris@networkdefines.com /
                hello@networkdefines.com
              </CompanyDetailsInfo>
            </CompanyDetails>
            <CompanyCopyright>
              Copyright© Network Defines, Inc. All rights reserved.
            </CompanyCopyright>
          </CompanyArea>
        </Footer>
      </FooterContainer>
    </FooterSection>
  );
}
